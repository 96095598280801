import React, { useEffect, useState } from "react"
import styled from "styled-components"

import SEO from "../components/seo"

import { GridContainer } from "../components/grid"
import { SimpleTitle } from "../components/heros"
import { graphql, PageProps } from "gatsby"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import { PrimaryButton } from "../components/buttons"
import { BaseContainer } from "../components/containers"
import { H2, H3 } from "../components/text"

const StyledSimpleTitle = styled(SimpleTitle)`
  grid-column: 1 / span 12;
`

const CourseDetailContainer = styled(BaseContainer)`
  grid-column: 1 / span 12;
  display: grid;
  gap: 2rem;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.grey};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const Price = styled(H2)`
  color: ${({ theme }) => theme.colors.beukelaarGreen};
  margin-bottom: 1rem;
`

const CourseDetailPage: React.FC<PageProps> = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false)

  const stripeData = data as any
  const priceId = stripeData.price.id
  const priceAmount = stripeData.price.unit_amount
  const productName = stripeData.price.product.name
  const productDescription = stripeData.price.product.description

  let stripePromise: Promise<Stripe | null>

  const getStripe = async () => {
    if (stripePromise) return stripePromise
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY!)
    return stripePromise
  }

  useEffect(() => {
    getStripe()
  }, [])

  const getCheckoutSessionId = async () => {
    const response = await fetch(
      process.env.GATSBY_STRIPE_CREATE_SESSION_API!,
      {
        method: "POST",
        body: JSON.stringify({
          priceId: priceId,
          successUrl: `${window.location.origin}/cursussen/betaling-geslaagd/`,
          cancelUrl: `${window.location.origin}/cursussen/betaling-mislukt/`,
        }),
      }
    )

    if (response.status !== 200) return

    const data = await response.json()
    return data.id as string
  }

  const redirectToCheckout = async () => {
    setIsLoading(true)
    const stripe = await getStripe()
    if (stripe === null || undefined) return

    const checkoutSessionId = await getCheckoutSessionId()
    if (checkoutSessionId === undefined) return

    const { error } = await stripe.redirectToCheckout({
      sessionId: checkoutSessionId,
    })

    if (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  return (
    <>
      <SEO title={`Cursus ${productName}`} description={productDescription} />
      <GridContainer>
        <StyledSimpleTitle>Controleer uw bestelling</StyledSimpleTitle>
        <CourseDetailContainer>
          <div>
            <Price>€ {priceAmount / 100}</Price>
            <H3>Cursus: {productName}</H3>
            <p>{productDescription}</p>
          </div>
          <div>
            <PrimaryButton disabled={isLoading} onClick={redirectToCheckout}>
              {isLoading ? "Laden.." : "Betalen"}
            </PrimaryButton>
          </div>
        </CourseDetailContainer>
      </GridContainer>
    </>
  )
}

export const courseDetailQuery = graphql`
  query StripeCourseDetail($productName: String!) {
    price: stripePrice(product: { name: { eq: $productName } }) {
      id
      unit_amount
      product {
        id
        name
        description
      }
    }
  }
`

export default CourseDetailPage
